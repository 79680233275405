.inputOTP{
  background: transparent;
  box-shadow: 0px 0px 3px #00b6b6;
  width: 40px;
  height: 40px;
  margin-inline: 5px;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 1.2em;
}


.coloredOTP{
  color: #00fafa;
}

.containerOTP{
  width: 100%;
  display: flex;
  justify-content: center;
}

