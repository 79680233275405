.bodyClass{
  display: flex;
  justify-content: center;
  background: grey;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10px;
}

.paddingContainer{
  padding-inline: 30px;
}

.container{
  width: 210mm;
  height: 297mm;
  background-color: white;
}

.reportTitle{
  display: flex;
  width: 100%;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 15px;
  margin-top: 50px;
}

.secondTitle{
  font-size: 18px;
  margin-bottom: 60px;
  padding-inline: 50px;
  font-style: italic;
  text-align: center;

}

.contentTitle{
  color: black;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 25px;
}

.contentList{
  margin-bottom: 40px;
}

.mainContent{
  display: flex;
  position: relative;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.mainContent .listNumber{
  margin-right: 15px;
}

.mainLink{
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pageNumber{
  position: absolute;
  right: 5px;
}

.subContentContainer{
  margin-left: 40px;
  font-size: 16px;  
}

.subContentContainer .subContent{
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}

.subContent .listNumber{
  margin-right: 10px;
}



.sectionTitle{
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}

.sectionDesc{
  text-align: justify;
  margin-bottom: 10px;
}

.sectionSubtitle{
  font-size: 22px;
  font-weight: 600;
}

.secondaryTitle{
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 15px;
}

.valueBoxPDF{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

.valueContainerPDF{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 60px;
  border-radius: 15px;
  font-weight: 600;
  font-size: 25px;
}

.chartImg{
  width: 200px;
  height: 200px;
}

.chartSectionPDF{
  width: 100%;
}

.tableContainer{
  display: flex;
  width: 100%;
  justify-content: center;
}

.styledTable{
  width: 650px;
  border-collapse: collapse;
    border-spacing: 0;
}

.styledHeader{
  background: black;
  color: white;
}

.styledRow{
  color: black;
  background-color: white;
}

.styledRow2{
  color: black;
  background-color: lightgray;
}

td{
  text-align:center;
  border: none;
}

td, th{
  padding: 6px 0;
}

th{
  border: 1px solid black;
}

.priceData{
  width: 140px;
}

.nameTD{
  max-width: 100px;
}

.typeTD{
  width: 130px;
}

.buyDateTD{
  width: 160px;
}