.listbox{
  height: 40px;
  position: relative;
}
.testo{
  margin-top: 2px;
}

.opt{
  background-color: #242424;
  z-index: 1;
}

.listButton{
  display: flex;
  flex-direction: row;
  padding: 0px 15px;
}

.valueSpan{
  padding-right: 5%;
}

.listOption{
  border-bottom: 1px solid lightgray;
  padding: 1px 0px;
}

.listMenu{
  top: 45px;
  z-index: 2;
  border: 1px solid grey;
}