.wrapper{
  width: 80%;
}

.barContainer{
  background-color: #00dada33;
  border-radius: 5px;
  height: 20px;
}

.label{
  color: transparent;
}