.customTransition{
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  flex: 1 1 1;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.MuiSlider-valueLabel{
  border-radius: 50%;
  background-color: white;
}

.navigationList{
  display: flex;
  justify-content: space-around;
  background-color: #242424;
  border-bottom: 2px solid #363636;
  margin-bottom: 20px;
  font-size: 16px;
}

.tabCustom{
  color: white;
  padding: 8px 0px;
}

.br{
  border-right: 2px solid #363636;
}

.tabSel{
  background-color: #181818;
  color: #00dada;
}

.tabCustom:focus {
  outline: none;
}

.tabSel:focus {
  outline: none;
}

.tabCustom:active {
  outline: none;
}

.tabSel:active {
  outline: none;
}
