:root {
  --w-size: 100px;
  --w-margin: calc(var(--w-size) / -2);
  --b-size: 70%;
  --box-wrapper-width: var(--b-size);
  --box-wrapper-height: var(--b-size);
  --box-wrapper-margin: calc((100% - var(--box-wrapper-height))/2) calc((100% - var(--box-wrapper-width))/2);
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.loading-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: var(--w-margin);
  width: var(--w-size);
  height: var(--w-size);
  background-color: transparent;
  border: none;
  -webkit-user-select: none;
}

.loading-box-wrap {
  width: var(--box-wrapper-width);
  height: var(--box-wrapper-height);
  margin: var(--box-wrapper-margin);
  position: relative;
  transform: rotate(-45deg);
}

.loadingbox {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(135, 0, 0, 0.6);
  background: linear-gradient(to right, #00dada, #3388cc, #0066aa, #004488, #002266, #6600cc, #5b33b2, #4e6699, #407980, #339c66, #26bf4d, #0ce61a, #61DE70, #1bdaaf, #00dada);
  background-position: 0% 50%;
  background-size: 1000% 1000%;
  visibility: hidden;
}

.loadingbox.one {
  animation: moveGradient 15s infinite, oneMove 3.5s infinite;
}

.loadingbox.two {
  animation: moveGradient 15s infinite, twoMove 3.5s 0.15s infinite;
}

.loadingbox.three {
  animation: moveGradient 15s infinite, threeMove 3.5s 0.3s infinite;
}

.loadingbox.four {
  animation: moveGradient 15s infinite, fourMove 3.5s 0.575s infinite;
}

.loadingbox.five {
  animation: moveGradient 15s infinite, fiveMove 3.5s 0.725s infinite;
}

.loadingbox.six {
  animation: moveGradient 15s infinite, sixMove 3.5s 0.875s infinite;
}

@keyframes moveGradient {

  to {
      background-position: 100% 50%
  }
}

@keyframes oneMove {

  0% {
      visibility: visible;
      clip-path: inset(0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  14.2857% {
      clip-path: inset(0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  28.5714% {
      clip-path: inset(35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  42.8571% {
      clip-path: inset(35% 70% 35% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  57.1428% {
      clip-path: inset(35% 70% 35% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  71.4285% {
      clip-path: inset(0% 70% 70% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  85.7142% {
      clip-path: inset(0% 70% 70% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  100% {
      clip-path: inset(0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }
}

@keyframes twoMove {

  0% {
      visibility: visible;
      clip-path: inset(0% 70% 70% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  14.2857% {
      clip-path: inset(0% 70% 70% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  28.5714% {
      clip-path: inset(0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  42.8571% {
      clip-path: inset(0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  57.1428% {
      clip-path: inset(35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  71.4285% {
      clip-path: inset(35% 70% 35% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  85.7142% {
      clip-path: inset(35% 70% 35% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  100% {
      clip-path: inset(0% 70% 70% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }
}

@keyframes threeMove {

  0% {
      visibility: visible;
      clip-path: inset(35% 70% 35% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  14.2857% {
      clip-path: inset(35% 70% 35% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  28.5714% {
      clip-path: inset(0% 70% 70% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  42.8571% {
      clip-path: inset(0% 70% 70% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  57.1428% {
      clip-path: inset(0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }
  71.4285% {
      clip-path: inset(0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  85.7142% {
      clip-path: inset(35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  100% {
      clip-path: inset(35% 70% 35% 0 round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }
}

@keyframes fourMove {

  0% {
      visibility: visible;
      clip-path: inset(35% 0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  14.2857% {
      clip-path: inset(35% 0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  28.5714% {
      clip-path: inset(35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  42.8571% {
      clip-path: inset(70% 35% 0% 35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  57.1428% {
      clip-path: inset(70% 35% 0% 35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }
  71.4285% {
      clip-path: inset(70% 0 0 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  85.7142% {
      clip-path: inset(70% 0 0 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  100% {
      clip-path: inset(35% 0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }
}

@keyframes fiveMove {

  0% {
      visibility: visible;
      clip-path: inset(70% 0 0 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  14.2857% {
      clip-path: inset(70% 0 0 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  28.5714% {
      clip-path: inset(35% 0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  42.8571% {
      clip-path: inset(35% 0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  57.1428% {
      clip-path: inset(35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  71.4285% {
      clip-path: inset(70% 35% 0% 35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  85.7142% {
      clip-path: inset(70% 35% 0% 35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  100% {
      clip-path: inset(70% 0 0 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }
}

@keyframes sixMove {

  0% {
      visibility: visible;
      clip-path: inset(70% 35% 0% 35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  14.2857% {
      clip-path: inset(70% 35% 0% 35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  28.5714% {
      clip-path: inset(70% 0 0 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  42.8571% {
      clip-path: inset(70% 0 0 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  57.1428% {
      clip-path: inset(35% 0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  71.4285% {
      clip-path: inset(35% 0% 35% 70% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  85.7142% {
      clip-path: inset(35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }

  100% {
      clip-path: inset(70% 35% 0% 35% round 5%);
      animation-timing-function: cubic-bezier(0.86,  0,  0.07,  1);
  }
}