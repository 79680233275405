.customTransition{
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  flex: 1 1 1;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.MuiSlider-valueLabel{
  border-radius: 50%;
  background-color: white;
}

.navigationWare{
  display: flex;
  justify-content: space-around;
  background-color: #242424;
  border-bottom: 2px solid #363636;
  margin-bottom: 20px;
  font-size: 16px;
}

.tabCustomWare{
  color: white;
  padding: 8px 0px;
}

.brWare{
  border-right: 2px solid #363636;
}

.warehouseTab{
  background-color: #181818;
  color: #00dada;
}

.tabCustomWare:focus {
  outline: none;
}

.warehouseTab:focus {
  outline: none;
}

.tabCustomWare:active {
  outline: none;
}

.warehouseTab:active {
  outline: none;
}

.radioWarehouse{
  display: flex;
  color: white;
  flex-wrap: wrap;
}