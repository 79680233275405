.smskill{
  width:150px;
  height:150px;
  position: relative;
  margin-top: 8%;
  rotate: -70deg;
}

.smouter{
  height: 150px;
  width: 150px;
  border-radius: 50%;
  padding: 20px;
  background-color: #242424;
}

.sminner{
  height: 110px;
  width: 110px;
  border-radius: 50%;
  background-color: #181818;
  display: flex;
  justify-content: center;
  align-items: center;
}


.smsvgCircle{
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}