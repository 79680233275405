.customList{
  width: 60%;
  background-color: #181818;
}

.selectedTab{
  background: linear-gradient(to right, #006d6d, #00dada);
}

.customTab{
  color: rgba(255,255,255,1);
}

.tabPanel{
  width: 100%;
  height: 100%;
  background-color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
}

.individualPanel{
  width: 90%;
  margin-inline: 15px;
}