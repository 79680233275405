.hideNav{
  transform: translateY(-100%);
}

.bm-burger-button{
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  left: 20px;
}

.bm-cross-button{
  position: absolute;
  top: 10px;
  left: 20px;
}

#cross {
  position: absolute;
  top: 10px;
  left: 0;
  height: 20px;
  width: 20px;
}

#headerMenu{
  display: flex;
}